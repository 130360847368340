import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

function Contact() {

  return (
    <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 2,
            pb: 0,
          }}
        >
      <Container maxWidth="lg" >
      <Typography variant="h4" component="h2" color="#2c387e;"><strong>
            Contact Us:</strong>
      </Typography>
      <Card sx={{ maxWidth: 450, m: 3 }} raised={true}>
      <CardContent>
      <Typography>
      <strong>Eldan Cohen</strong> <br/>
      Department of Mechanical & Industrial Engineering <br/>
      University of Toronto <br/>
      5 King's College Rd. <br/>
      Toronto, ON M5S 3G8 <br/> <br/>
      Phone: +1 416-978-4184 <br/>
      Email: ecohen AT mie utoronto ca
      </Typography>
      </CardContent>
      </Card>
      </Container>
    </Box>
  );
}

export default Contact;
