import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';

import {parseBibFile, normalizeFieldValue} from "bibtex";


function getAuthors(entry) {
  const authorField = entry.getField("author");
  return authorField.authors$.map((author, i) => (author.lastNames.join(" ") + ", " + author.firstNames.map((fn) => {return fn[0] + ".";}).join(" "))).join("; ")
}

function getTitle(entry) {
  const titleField = entry.getField("TITLE");
  return normalizeFieldValue(titleField);
}

function getVenue(entry) {
  switch (entry.type) {
  case "inproceedings":
    return normalizeFieldValue(entry.getField("booktitle"));
  case "article":
    var volume_text = "";
    if (typeof entry.getField("volume") !== 'undefined') {
      volume_text = ", " + normalizeFieldValue(entry.getField("volume"))
    }
    return normalizeFieldValue(entry.getField("journal")) + volume_text;
  default:
    return "";
  }
}

function getPages(entry) {
  const pagesField = entry.getField("pages");
  return normalizeFieldValue(pagesField).replace("--", "-");
}

function getYear(entry) {
  const pagesField = entry.getField("year");
  return normalizeFieldValue(pagesField);
}

function getURL(entry) {
  const urlField = entry.getField("url");
  if (urlField) {
    return normalizeFieldValue(urlField);
  } else {
    return "";
  }
}

function getArxiv(entry) {
  const urlField = entry.getField("arxiv");
  if (urlField) {
    return normalizeFieldValue(urlField);
  } else {
    return "";
  }
}

function getAppendix(entry) {
  const urlField = entry.getField("appendix");
  if (urlField) {
    return normalizeFieldValue(urlField);
  } else {
    return "";
  }
}

function Publications() {

  const [data, updateData] = React.useState([]);
  React.useEffect(() => {
    const getData = async () => {
      const resp = await fetch('/papers.bib').then((r) => r.text()).then(text => parseBibFile(text));
      updateData(Object.values(resp.entries$).sort((item1, item2) => parseInt(normalizeFieldValue(item2.getField("Year"))) - parseInt(normalizeFieldValue(item1.getField("Year")))));
    }
    getData();
  }, []);

  return (
    <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 2,
            pb: 0,
          }}
        >
      <Container maxWidth="lg">
      <Typography variant="h4" component="h2" color="#2c387e;"><strong>
        Publications:</strong>
        </Typography>
      <List>
      { data.map((entry, idx) => (
        <ListItem key={entry._id}>
        <ListItemText> {((idx == 0) || getYear(data[idx]) != getYear(data[idx-1])) ? <Divider sx={{mb: 2}}><Chip sx={{p:2, fontWeight: 'bold', fontSize: 'large'}} label={getYear(data[idx])}/></Divider> : ""} <strong>{getTitle(entry)}.</strong> {getAuthors(entry)} In <i>{getVenue(entry)},</i> {getPages(entry)}, {getYear(entry)}.
        <Stack direction="row" spacing={1}>
        {(getURL(entry) != "") ? <Button variant="outlined" size="small" color="secondary" href={getURL(entry)}>Paper</Button> : ""} {(getArxiv(entry) != "") ? <Button variant="outlined" size="small" color="secondary" href={getArxiv(entry)}>arXiv</Button> : ""} {(getAppendix(entry) != "") ? <Button variant="outlined" size="small" color="secondary" href={getAppendix(entry)}>Appendix</Button> : ""}
        </Stack>
        </ListItemText>

      </ListItem>
        )) }
      </List>

      </Container>
    </Box>
  );
}

export default Publications;
