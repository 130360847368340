import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import WebsiteNavigationBar from './WebsiteNavigationBar';

export default function MenuAppBar() {

  return (
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 2,
            pb: 0,
          }}
        >
          <Container maxWidth="lg">

          <Grid container spacing={2} alignItems="end" justifyContent="center" sx={{
            pt: 0,
            pb: 2,
          }}>
            <Grid item md={3} sm={5} xs={0} sx={{textAlign: "center"}}>
              <img src="./U-of-T-logo.svg" width="85%"/>
            </Grid>
            <Grid item md={9} sm={7} xs={12} sx={{borderLeft: 0, borderColor: "lightgrey"}}>
              <Typography component="h1" variant="h4" color="#2c387e;" gutterBottom>
              <strong>The Optimization and Machine Learning (OptiMaL) Lab</strong>
            </Typography>
            </Grid>
          </Grid>
          <WebsiteNavigationBar/>
          </Container>
        </Box>
    );
}
