import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';

function ProfileCard(props) {
  const { post } = props;

  return (
    <Card variant="outlined">
    <CardMedia align="center" image={props.image} title={props.name} sx={{ height: 140 }} />
        
    </Card>
  );
}

export default ProfileCard;
