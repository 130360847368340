import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

import { useLocation } from "react-router-dom";


function Photo() {
  const pathname = useLocation().pathname;
  if ((pathname == "/") || (pathname.toLowerCase() == "/home")){
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        Home page background <a href="https://unsplash.com/photos/SWGW-rW0zeE">photo</a> by Anders Jildén.
      </Typography>
    );
  } else if (pathname.toLowerCase() == "/research") {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        Photos by <a href="https://unsplash.com/photos/black-and-silver-laptop-computer-IrRbSND5EUc">Markus Winkler</a>, <a href="https://unsplash.com/photos/low-angle-photography-of-metal-structure-ZiQkhI7417A">Alina Grubnyak</a>, and <a href="https://unsplash.com/photos/turned-on-black-and-grey-laptop-computer-mcSDtbWXUZU">Lukas Blazek</a>.
      </Typography>
      );
  } else {
    return;
  }
}

export default function MenuAppBar() {

  return (
    <Box sx={{ bgcolor: 'background.paper', pt: 6 }} component="footer">
      <Container maxWidth="lg">

      <Grid container alignItems="center" sx={{
            pt: 1,
            pb: 2,
            borderTop: 2,
            borderColor: "#2c387e"
          }}>
            <Grid item md={4} sm={6} xs={12}>
              <img src="./mielogo2.png" width="80%"/>
            </Grid>
            <Grid item md={8} sm={6} xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Typography sx={{fontSize: '0.9rem'}}>
               University of Toronto <br/> Department of Mechanical and Industrial Engineering <br/> 5 King's College Road <br/> Toronto, Ontario • M5S 3G8 • Canada
              </Typography>
            </Box>
            </Grid>
      </Grid>

      <Photo />
      </Container>
    </Box>
    );
}
