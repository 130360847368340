import * as React from 'react';
import Home from './pages/Home.js';
import Publications from './pages/Publications.js';
import People from './pages/People.js';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import Contact from './pages/Contact.js';
import Research from './pages/Research.js';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";



export default function App() {
  return (
    <div className="App">
      <Router basename={process.env.PUBLIC_URL}>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Publications" element={<Publications />} />
          <Route path="/People" element={<People />} />
          <Route path="/Research" element={<Research />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
      
    </div>
  );
}
