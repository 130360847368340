import * as React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';

import { green, pink } from '@mui/material/colors';

function News(props) {
  const { news } = props;

  return (
    <div>
    <Typography variant="h4" component="h2" color="#2c387e;"><strong>
    Recent News:</strong>
    </Typography>
    <List sx={{ width: '100%', maxWidth: '90%', bgcolor: 'background.paper' }}>
    {news.map(item => (
      <ListItem key={item.text}>
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: pink[500] }}>
            {item.icon}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={item.text} secondary={item.date} />
      </ListItem>
      ))}
      </List>
      </div>
  );
}

export default News;
