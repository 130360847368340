import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import Modal from '@mui/material/Modal';

const projects = [
  {title: "Interpretable Time Series Forecasting", image: "imgs_research/markus-winkler-IrRbSND5EUc-unsplash.jpg", link: "http://link", keywords: ["Machine Learning", "Interpretability"]}, 
  {title: "Neural Language Models for Classifying Patient Safety Events in Hospitals", image: "imgs_research/pse_classification.png", link: "http://link2", keywords: ["Machine Learning", "Interpretability", "NLP"]}, 
  {title: "Neural Sequence Generation", image: "imgs_research/alina-grubnyak-ZiQkhI7417A-unsplash.jpg", link: "http://link2", keywords: ["Machine Learning", "Optimization"]}, 
  {title: "Optimal Decision Trees", image: "imgs_research/lukas-blazek-mcSDtbWXUZU-unsplash.jpg", link: "http://link2", keywords: ["Machine Learning", "Interpretability", "Optimization"]}, 
  {title: "Interpretable Clustering", image: "imgs_research/interpretable_clustering.jpg", link: "http://link2", keywords: ["Machine Learning", "Interpretability", "Optimization"]}, 
  ];

function Research() {

  return (
    <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 2,
            pb: 0,
          }}
        >
      <Container maxWidth="lg" >
      <Typography variant="h4" component="h2" color="#2c387e;"><strong>
            Selected Research Projects:</strong>
      </Typography>
          <Grid container spacing={4} sx={{pt: 2}}>
            {projects.map((proj) => (
              <Grid item key={proj.title} md={4} sm={6} xs={12}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                <CardActionArea>
                  <CardMedia
                    component="div"
                    sx={{
                      // 16:9
                      pt: '56.25%',
                    }}
                    image={proj.image}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      <strong>{proj.title}</strong>
                    </Typography>
                    <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
                    {proj.keywords.map((word) => (
                      <Chip key={proj.title + "_" + word} label={word} />
                      ))}
                    </Stack>
                  </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
      </Container>
    </Box>
  );
}

export default Research;
