import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import MainFeaturedPost from '../components/MainFeaturedPost';
import News from '../components/News';
import FeedIcon from '@mui/icons-material/Feed';

const bg_images = ['bgs/anders-jilden-SWGW-rW0zeE-unsplash.jpg']

function Home() {

  const mainFeaturedPost = {
    title: 'Our Research:',
    description:
      "The OptiMaL research group is concerned with developing novel optimization and machine learning algorithms for a range of application domains, with an emphasis on interpretable and human-compatible approaches.",
    image: bg_images[Math.floor(Math.random() * bg_images.length)],
    imageText: 'main image description',
    linkText: '',
  };

  const newsList = [
    {text: 'Paper accepted to ECAI 2024: "NeurCAM: Interpretable Neural Clustering via Additive Models"', date: "July 9, 2024", icon: <FeedIcon/>},
    {text: 'Paper accepted to HFES 2024: "Evaluating Active Learning Strategies for Automated Classification of Patient Safety Event Reports in Hospitals"', date: "July 8, 2024", icon: <FeedIcon/>},
    {text: 'Two papers accepted to SoCS 2024: "Bi-Criteria Diverse Plan Selection via Beam Search Approximation" and "Neural Sequence Generation with Constraints via Beam Search with Cuts: A Case Study on VRP"', date: "April 6, 2024", icon: <FeedIcon/>},
    {text: 'Paper accepted to NAACL 2024 Findings: "Gaussian Process Optimization for Adaptable Multi-Objective Text Generation using Linearly-Weighted Language Models"', date: "April 1, 2024", icon: <FeedIcon/>},
    {text: 'Journal paper accepted to EAAI: "Transformer Models for Mining Intents and Predicting Activities from Emails in Knowledge-Intensive Processes"', date: "December 15, 2023", icon: <FeedIcon/>},
    // {text: 'Journal paper accepted to JMIR HF: "Automated Classification of Patient Safety Event Reports: A Machine Learning Approach with Human-AI Collaboration"', date: "December 4, 2023", icon: <FeedIcon/>},
    // {text: 'Paper accepted to CP 2023: "SAT-Based Learning of Compact Binary Decision Diagrams for Classification"', date: "July 10, 2023", icon: <FeedIcon/>},
    // {text: 'Paper accepted to IJCAI 2023: "Optimal Decision Trees For Interpretable Clustering with Constraints"', date: "April 20, 2023", icon: <FeedIcon/>},
    ];

  return (
    <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 1,
            pb: 0,
          }}
        >
      <Container maxWidth="lg">
        <MainFeaturedPost post={mainFeaturedPost} />
        <Grid container spacing={4}>
        <Grid item md={8} sm={6} xs={12}>
        <News news={newsList}/>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
        <Typography variant="h4" component="h2" color="#2c387e;"><strong>
        Group Photo:</strong>
        </Typography>
        <Box
        component="img"
        sx={{
          pt: 3,
          width: '100%',
        }}
        alt="Group photo."
        src="imgs/Group.jpg"/>
        </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Home;
